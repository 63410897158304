import React from 'react';
import Layout from '../../components/Layout';
import blogd1 from '../../assets/images/cmac.jpeg';
import blogd2 from '../../assets/images/about-4.jpg';
// import blogd3 from '../../assets/images/2022/nurse-ndidi-retire/3.jpg';
// import blogd4 from '../../assets/images/2022/nurse-ndidi-retire/4.jpg';

import NavOne from '../../components/NavOne';
import PageHeader from '../../components/PageHeader';
import Footer from '../../components/Footer';

const NewsDetails = () => {
  return (
    <section className='blog-details'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='blog-one__single'>
              <div className='blog-one__content text-center'>
                <h2 className='blog-one__title'>
                  DR. AYODEJI OLUWOLE APPOINTED AS CHAIRMAN, MEDICAL ADVISORY
                  COMMITTEE
                </h2>
                <div className='blog-one__image'>
                  <img src={blogd2} alt='' />
                </div>
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  <strong>The Lagos University Teaching Hospital</strong>{' '}
                  announces the appointment of{' '}
                  <strong>Dr Ayodeji Oluwole</strong>, a distinguished Honorary
                  Consultant with the Hospital, as the Substantive Chairman,
                  Medical Advisory Committee of the Hospital for a renewable
                  term of 2 [two] years, with effect from 1st January, 2024.
                </p>
                <div className='row'>
                  <div className='col-lg-6'>
                    <img src={blogd1} height={370} />
                  </div>
                  <div
                    className='col-lg-6'
                    style={{ textAlign: 'left', alignItems: 'right' }}
                  >
                    {/* <img src={blogd3} height={370}/> */}
                    <p>
                      Dr Oluwole, a Fellow of the National Postgraduate Medical
                      College of Nigeria (NPMCN), is very resourceful and has
                      built a promising career for himself over a period of 18
                      years in Maternal Medicine and Gynaecology Endoscopic
                      Surgery Subspecialty, rising to his current position of an
                      Associate Professor at the College of Medicine University
                      of Lagos (CMUL) by dint of hard work and academic
                      excellence.
                      <br />
                      <br />
                      Dr Oluwole's passion and commitment to the development of
                      medical practice was recognised when he was elected the
                      Chairman of the Faculty of Obstetrics & Gynaecology at the
                      National Postgraduate Medical College of Nigeria on 1st
                      January, 2023 after holding other important positions in
                      the Faculty, College and the Society of Gynaecology and
                      Obstetrics of Nigeria [SOGON] between 2011 and 2021.
                    </p>
                  </div>
                </div>
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  {/* Nurse Ndidi Enuwa Retires */}
                </span>
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  His impeccable character and sympathetic understanding of
                  other people's problems which suggests considerable potential
                  in the area of management, helped to ensure his consideration
                  for leadership positions in the Hospital.
                  <br />
                  Dr Oluwole served as the Deputy Chairman, Medical Advisory
                  Committee [Clinical] for 2 [two] terms, from 1st August, 2019
                  to 31st July, 2023; and Acting Chairman, Medical Advisory
                  Committee from 15th May, 2023 to 31st December, 2023; before
                  his new appointment as Substantive CMAC of the Hospital.
                  <br />
                  With his wealth of knowledge, experience and exposure garnered
                  from previous academic, management and leadership positions,
                  we have no doubt that Dr Oluwole has been well prepared for
                  the job and will contribute immensely towards taking the
                  Hospital to the next level.
                  <br />
                  We pray that the Almighty God shall grant him the required
                  wisdom and wherewithal to actualize the set goals of his new
                  office and enable him to assist the management in meeting its
                  objectives and the Hospital's vision.
                  <br />
                  We congratulate Dr Oluwole on this achievement and wish him
                  all the best in his new assignment.
                </p>
                <div className='row'>
                  <div className='col-lg-12'>
                    {/* <img src={blogd4} height={370} /> */}
                  </div>
                </div>
                {/* <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  Baale of Mashewele community, community elder, HOD of
                  Community Health Dept Prof Ogunnowo, Dr Roberts ,Mushin LGA
                  Health Educator Mrs Akinterinwa and Residents in Community
                  Health.
                </span>
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  Other pictures as found below:
                </p>
                <div className='row'>
                  <div className='col-lg-6'>
                    <img src={blogd5} height={370} />
                  </div>
                  <div className='col-lg-6'>
                    <img src={blogd6} height={370} />
                  </div>
                </div>
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  Fig 1: Residents at the department before the program
                  <br />
                  Fig 2: Dr Roberts and Dr Balogun Consultants at Dept of Comm
                  Health at the Outreach.
                </span>
                <div className='row'>
                  <div className='col-lg-12'>
                    <img src={blogd7} height={370} />
                  </div>
                </div>
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  Residents dispensing medications in advance for the outreach
                </span>
              </div>
            </div>
            <div className='share-block'>
              <div className='left-block'>
                <p>
                  Tags: <a href='#none'>Community Health Department</a>
                </p>
              </div> */}
                {/* <div className='social-block'>
              <a href='#none'>
                <i className='fab fa-twitter'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-facebook-f'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-instagram'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-dribbble'></i>
              </a>
            </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const GalleryPage = () => {
  return (
    <Layout pageTitle='LAGOS UNIVERSITY TEACHING HOSPITAL | LUTH News'>
      <NavOne />
      <PageHeader title='' />
      <NewsDetails />
      <Footer />
    </Layout>
  );
};

export default GalleryPage;
